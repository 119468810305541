<template>
  <div class="list row">

  <div class="container-fluid">

    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Concursuri</h1>
    </div>

    <div class="row"  v-if="concursuri.length">

      <div class="col-xl-3 col-md-6 mb-4 cursor-pointer-default" 
        
        v-for="(concurs, index) in concursuri"
        :key="index" 
        @click="goToActiveConcurs(concurs)"
      >
          <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                               {{ concurs.concurs.data }}</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">{{ concurs.concurs.nume }}</div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

       <div class="col-xl-3 col-md-6 mb-4 cursor-pointer-default" @click="adaugaConcurs = !adaugaConcurs" >
          <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Concurs Nou</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">Adauga</div>

                      </div>
                      <div class="col-auto">
                          <i class="fas fa-plus fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </div>
    <div v-else>
      <div class="card shadow mb-4">
        <div class="card-body allow-overflow">
          <div class="fa-1x text-center text-primary mb-4 mt-4">
            Se incarca &nbsp; <i class="fas fa-fish fa-spin"></i>
          </div>
        </div>
      </div>
    </div>


    <div class="wrap_form_group form-group" v-if="adaugaConcurs">
       <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">O noua aventura!</h6>
        </div>
        <div class="card-body">
          <concurs-adauga @refreshConcursuri="refreshConcursuri" />
        </div>
      </div>
    </div>

  </div>



  </div>
</template>
<script>
import PescariDataService from "../services/PescariDataService";
import ConcursAdauga from "./ConcursAdauga";

export default {
  name: "concursuri-list",
  components: { 
    ConcursAdauga,
  },
  data() {
    return {
      concursuri: [],
      adaugaConcurs: false,
    };
  },
  methods: {
    goToAdaugaConcurs(){
      this.$router.push({ name: 'adauga-concurs' });
    },
    goToActiveConcurs(concurs) {
      this.$router.push({ name: 'edit-concurs', params: { idConcurs: concurs.id } })
    },
    refreshConcursuri(){
      PescariDataService.getConcursuri().then( qSnap => {
          this.concursuri = qSnap;
        });
    }
  },
  mounted() {
    this.refreshConcursuri();
  },
};
</script>
<style >
.cursor-pointer{
  cursor:pointer;
  transition:all 0.2s ease-in;
}
.cursor-pointer:hover{
  background-color:#3f9bfc;
  color: #fff;
}
h4{
  margin-bottom:20px;
}
.navInline{
  margin-top:20px;
}
.navInline .nav-link{
  display:inline;
}
.list-group-item+.list-group-item.active{
  margin-top:0px!important;
  border-top-width:0px!important;
}

</style>