<template>


    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Adauga Concurs</h1>
    </div>



    <div class="submit-form">
      <div class="wrap_form_group" v-if="!submitted">
        <form @submit.prevent="saveConcurs()">
          <div class="form-group">
            <label for="nume">Nume concurs</label>
            <input
              type="text"
              class="form-control"
              id="nume"
              required
              v-model="concurs.nume"
              name="nume"
            />
          </div>
          <div class="form-group">
            <label for="editia">Editia</label>
            <input
              type="text"
              class="form-control"
              id="editia"
              required
              v-model="concurs.editia"
              name="editia"
            />
          </div>
          <div class="form-group">
            <label for="data">Data</label>
            <input
              type="text"
              class="form-control"
              id="data"
              required
              v-model="concurs.data"
              name="data"
            />
          </div>
          <div class="form-group">
            <label for="locatie">Locatie</label>
            <input
              type="text"
              class="form-control"
              id="locatie"
              required
              v-model="concurs.locatie"
              name="locatie"
            />
          </div>
          <button type="submit" class="btn btn-success">Adauga</button>
        </form>


      </div>
      <div v-else>
        <p>Concursul a fost creat!</p>
        <button class="btn btn-success" @click="newConcurs">Adauga unul nou</button>
      </div>
    </div>



</template>
<script>
import PescariDataService from "../services/PescariDataService";
export default {
  name: "concurs-adauga",
  emits: ["refreshConcursuri"],
  data() {
    return {
      concurs: {
        nume: "",
        data: "",
        locatie: "",
        editia: "",
      },
      submitted: false
    };
  },
  methods: {
    saveConcurs() {
      var data = {
        nume: this.concurs.nume,
        data: this.concurs.data,
        locatie: this.concurs.locatie,
        editia: this.concurs.editia,
      };
      PescariDataService.createConcurs(data)
        .then(() => {
          this.submitted = true;
          this.$emit("refreshConcursuri");
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    newConcurs() {
      this.submitted = false;
      this.concurs = {
        nume: "",
        data: "",
        locatie: "",
        editia: ""
      };
    }
  }
};
</script>
<style>

</style>